import { Component, ElementRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NetlifyFormsService } from "./../../netlify-forms.service";
import { Subscription } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Newsletter } from "../../../interface/Newsletter";

@Component({
  selector: "app-newsletter",
  templateUrl: "./newsletter.component.html",
  styleUrls: ["./newsletter.component.css"],
})
export class NewsletterComponent {
  // attributes for newsletter form
  email2: "";
  loading2: boolean;
  emailSent2: boolean;
  emailFailed2: boolean;
  form2: FormGroup;
  formSuccess2 = false;
  submitted2 = false;

  url1 =
    "https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/js/bootstrap.bundle.min.js";
  url2 = "https://unpkg.com/swiper/swiper-bundle.min.js";
  url3 = "https://unpkg.com/aos@next/dist/aos.js";
  url4 = "https://cdnjs.cloudflare.com/ajax/libs/Swiper/3.3.1/js/swiper.js";

  constructor(
    private elementRef: ElementRef,
    private netlifyForms: NetlifyFormsService,
    private formBuilder: FormBuilder
  ) {}

  private formStatusSub: Subscription;
  private formStatusSub2: Subscription;


  ngOnInit() {
    // Newsletter Form validation
    this.form2 = this.formBuilder.group({
      email2: [
        "",
        [
          Validators.required,
          Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"),
        ],
      ],
    });
  }

  // Submit newsletter form
  onSubmit2() {
    // Change form status
    this.submitted2 = true;

    // stop here if form is invalid
    if (this.form2.invalid) {
      return;
    }

    // Load data
    const data = {
      email: this.form2.value.email2,
    };

    const entry = {
      ...data,
    } as Newsletter;
    console.log(data, entry);
    this.formStatusSub = this.netlifyForms.submitNewsletter(entry).subscribe(
      (res) => {
        this.loading2 = false;
        this.emailSent2 = true;
        this.formSuccess2 = true;
        setTimeout(() => {
          this.emailSent2 = false;
        }, 10000);
        // contactForm.resetForm();
      },
      (err) => {
        this.loading2 = false;
        this.emailFailed2 = true;
        this.formSuccess2 = true;
        setTimeout(() => {
          this.emailFailed2 = false;
        }, 10000);
      }
    );
  }

  // convenience getter for easy access to newsletter form fields
  get f2() {
    return this.form2.controls;
  }

  ngAfterViewInit() {
    // Loading js file
    var s = document.createElement("script");
    s.src = "../assets/js/main.js";
    this.elementRef.nativeElement.appendChild(s);
  }
}
